import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { HttpMethod } from '../enums/httpmethod.enum';
import { HttpClientService, IHttpClientAdditionalClientOptions } from './http-client.service';

export enum ServiceType {
  COOK_SERVICE = 'cook',
  USER_SERVICE = 'user-service',
  KITCHEN_SERVICE = 'kitchen-service',
  MENU_SERVICE = 'menu-service',
}

/**
 *
 *
 * @export
 * @class RestApiService
 */
export class RestApiService {
  /**
   *
   *
   * @memberof RestApiService
   */
  protected baseApiUrl = 'http://localhost:3000';

  protected authenticatedRoute = false;

  /**
   * Creates an instance of RestApiService.
   * @param {HttpClient} http
   * @memberof RestApiService
   */
  constructor(private http: HttpClientService) {
    this.authenticatedRoute = false;
  }

  /**
   * It makes a call to the API and returns the result.
   * @param {string} path - The path to the API endpoint.
   * @param [isAuthenticatedRoute=false] - If true, the request will be authenticated.
   * @returns A promise.
   */
  httpGet(
    path: string,
    additionalClientOptions?: IHttpClientAdditionalClientOptions,
    isAuthenticatedRoute = this.authenticatedRoute || false,
  ): Promise<any> {
    return this.http.call(
      path,
      {},
      isAuthenticatedRoute,
      HttpMethod.GET,
      {},
      additionalClientOptions,
    );
  }

  /**
   * It makes a PUT request to the API.
   * It takes a path, a payload,
   *  and an optional isAuthenticatedRoute parameter. It returns a promise
   * that resolves to the response from the server
   * @param {string} path - The path to the API endpoint.
   * @param {object} payload - The data to be sent to the server.
   * @param [isAuthenticatedRoute=false] - If the route requires authentication, set this to true.
   * @returns A promise.
   */
  httpPost(
    path: string,
    payload: object = {},
    isAuthenticatedRoute = this.authenticatedRoute || false,
  ): Promise<any> {
    return this.http.call(path, payload, isAuthenticatedRoute, HttpMethod.POST);
  }

  /**
   * It makes a PUT request to the API.
   * @param {string} path - The path to the API endpoint.
   * @param {object} payload - The data to be sent to the server.
   * @param [isAuthenticatedRoute=false] - If the route requires authentication, set this to true.
   * @returns A promise.
   */
  httpPut(
    path: string,
    payload: object = {},
    isAuthenticatedRoute = this.authenticatedRoute || false,
  ): Promise<any> {
    return this.http.call(path, payload, isAuthenticatedRoute, HttpMethod.PUT);
  }

  /**
   * It makes a DELETE request to the specified path.
   * @param {string} path - The path to the API endpoint.
   * @param {object} payload - The data to be sent to the server.
   * @param [isAuthenticatedRoute=false] - If the route requires authentication, set this to true.
   * @returns The promise of the response.
   */
  httpDelete(
    path: string,
    payload: object = {},
    isAuthenticatedRoute = this.authenticatedRoute || false,
  ): Promise<any> {
    return this.http.call(path, payload, isAuthenticatedRoute, HttpMethod.DELETE);
  }

  /**
   * It makes a PATCH request to the specified path.
   * @param {string} path - The path to the API endpoint.
   * @param {object} payload - The data to be sent to the server.
   * @param [isAuthenticatedRoute=false] - If the route requires authentication, set this to true.
   * @returns The promise of the response.
   */
  httpPatch(
    path: string,
    payload: object = {},
    isAuthenticatedRoute = this.authenticatedRoute || false,
  ): Promise<any> {
    return this.http.call(path, payload, isAuthenticatedRoute, HttpMethod.PATCH);
  }

  /**
   * It handles errors.
   * @param {any} error - any
   * @returns The observable of empty strings, which will be the response body.
   */
  handleError(error: any) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      errorMessage = error.error.message;
    } else {
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    return throwError(errorMessage);
  }
}
