import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HotToastModule } from '@ngneat/hot-toast';
import { LoadingBarModule } from '@ngx-loading-bar/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  MSAL_INSTANCE, MsalInterceptor, MSAL_INTERCEPTOR_CONFIG, MsalService,
  MsalModule,
} from '@azure/msal-angular';
import { AbilityModule } from '@casl/angular';
import { PureAbility } from '@casl/ability';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { OrderApiService } from '../services/api/order-api.service';
import { S3Service } from '../services/upload.service';
import { UserApiService } from '../services/api/user-api.service';
import { AppAbility } from '../services/appAbility';
import { AbilityGuard } from '../guards/ability.guard';
import {
  AzureAdConfigService, MSALInstanceFactory, MSALInterceptorConfigFactory,
} from '../services/azure-ad-config.service';
import { StorageService } from '../services/storage.service';
import { ModalConfig } from '../lib/modal/modal-config';
import { ModalService } from '../lib/modal/modal.service';
import { AuthGuard } from '../guards/auth.guard';
import { HttpClientInterceptor } from '../interceptors/http.interceptor';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { NotFoundPageComponent } from './not-found-page/not-found-page.component';
import { SiteUnderMaintenanceModalComponent } from './shared/site-under-maintenance-modal/site-under-maintenance-modal.component';

export function initAuth(AzureAdConfigService: AzureAdConfigService) {
  return () => AzureAdConfigService.loadAppConfigurations();
}
@NgModule({
  declarations: [
    AppComponent,
    NotFoundPageComponent,
    SiteUnderMaintenanceModalComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    ReactiveFormsModule,
    AbilityModule,
    FormsModule,
    HotToastModule.forRoot({
      position: 'top-center',
      className: 'hot-toast',
      duration: 1500,
    }),
    LoadingBarModule,
    BrowserAnimationsModule,
    MsalModule,
  ],
  providers: [
    AuthGuard,
    AbilityGuard,
    StorageService,
    UserApiService,
    S3Service,
    ModalConfig,
    ModalService,
    MsalService,
    OrderApiService,
    AzureAdConfigService,
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpClientInterceptor,
      multi: true,
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initAuth,
      deps: [AzureAdConfigService],
      multi: true,
    },
    { provide: AppAbility, useValue: AppAbility() },
    { provide: PureAbility, useExisting: AppAbility },
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
