import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../guards/auth.guard';
import { AbilityGuard } from '../guards/ability.guard';

const routes: Routes = [{
  path: '',
  pathMatch: 'full',
  redirectTo: 'dashboard',
}, {
  path: 'dashboard',
  canActivate: [AuthGuard, AbilityGuard],
  loadChildren: () => import('./dashboard/dashboard.module').then((m) => m.DashboardModule),
}];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
