import { Injectable } from '@angular/core';
import { HotToastService } from '@ngneat/hot-toast';
import { IAdminUser, UserApiService } from './api/user-api.service';
import { StorageService } from './storage.service';
import { AzureAdConfigService } from './azure-ad-config.service';
import { StorageKey } from '../enums/storage-key.enum';
import { defineAbilities, AppAbility } from './appAbility';
import { AppTabAbilityKey } from '../enums/ability.enum';

@Injectable({
  providedIn: 'root',
})
export class InitialService {
  public userInfo!: IAdminUser;

  constructor(
    private userApiService: UserApiService,
    private storageService: StorageService,
    private azureAdConfigService: AzureAdConfigService,
    private readonly toast: HotToastService,
    private appAbility: AppAbility,
  ) { }

  async initialize() {
    try {
      if (this.azureAdConfigService.isAuthenticated) {
        const userGraph = await this.azureAdConfigService.getAzureUserProfile();
        if (userGraph?.id) {
          this.userInfo = (await this.userApiService.getAdminUserById(userGraph.id)).data;;
          if (!this.userInfo || !this.userInfo.isActive) {
            window.alert('Please contact the admin or the direct manager for the access. To locate your direct manager, check your Teams Organisation!');
            this.azureAdConfigService.logout();
          }
          this.storageService.set(StorageKey.USER_ABILITIES, this.userInfo.abilities);
          this.appAbility = this.fetchAbilities();
          if (!this.appAbility.can('view', AppTabAbilityKey.CONTROL_TOWER_APPLICATION)) {
            window.alert('Please contact the admin or the direct manager for the access. To locate your direct manager, check your Teams Organisation!');
            this.azureAdConfigService.logout();
          }
        } else {
          this.azureAdConfigService.logout();
        }
      }
    } catch (error) {
      window.alert('Please contact the admin or the direct manager for the access. To locate your direct manager, check your Teams Organisation!');
      this.azureAdConfigService.logout();
    }
  }

  public isUserAuthorized() {
    const ability = this.storageService.get(StorageKey.USER_ABILITIES);
    if (ability) return defineAbilities(ability);
    return undefined;
  }

  public fetchAbilities() {
    const ability = this.storageService.get(StorageKey.USER_ABILITIES);
    return defineAbilities(ability);
  }

  public async getElgibleParentZones(): Promise<string[] | string> {
    const userZones = this.userInfo.attributes.filter((attr) => attr.attributeName === 'zoneInfo').map((roles) => roles.attributeValue as Array<string>);
    const eligibleParentZones: string[] = [];

    if (userZones[0].includes('all')) {
      return 'All';
    }

    if (userZones[0]) {
      const parentZonesFromZoneInfo: Array<string> = userZones[0];
      eligibleParentZones.push(...parentZonesFromZoneInfo);
    }
    return eligibleParentZones;
  }

  public fetchUserRole() {
    const userRoles = this.userInfo.attributes.filter((attr) => attr.attributeName === 'roles').map((roles) => roles.attributeValue as string);
    return userRoles[0];
  }
}
