import { Injectable } from '@angular/core';
import { values } from 'lodash';
import { CALL_RESPONSE_TYPE, DELAY_NOTIFICATION_EVENT_TYPE } from 'src/enums/enums';
import { environment } from '../../environments/environment';
import { RestApiService } from '../api.service';
import { HttpClientService } from '../http-client.service';
import { ParentZonalDetail } from './zone-api.service';

export enum Tpl {
  PORTER = 'porter',
  DUNZO = 'dunzo',
  SHADOW_FAX = 'shadow-fax',
  ADLOGGS = 'ADLOGGS',
  ZOMATOXTREME = 'ZOMATOXTREME',
  QWQER = 'QWQER',
}

export enum TplVendors {
  PORTER = 'PORTER',
  DUNZO = 'DUNZO',
  SHADOW_FAX = 'SHADOW-FAX',
  ADLOGGS = 'ADLOGGS',
  ZOMATOXTREME = 'ZOMATOXTREME',
  QWQER = 'QWQER',
}

export enum PaymentTypeEnum {
  CASH = 'cash',
  ONLINE = 'online'
}

export interface IDeliveryTimeSlot {
  date: string;
  event: string;
  time: string;
}

export interface IOrderDelayType {
  delayType: string,
  delayTime: number;
}
export interface ILocationCoordinates {
  coordinates: number[];
  type: string;
}

export interface IDistanceStats {
  firstMileDelivery: number,
  lastMileDelivery: number,
  riderOrderAcceptanceLocation: ILocationCoordinates;
}
export interface IOrderResponse {
  _id: string;
  instructions?: string;
  specialCuisineType?: string;
  cusineTag?: string;
  parentZoneName?: string,
  parentZoneId: string;
  subZoneId: string;
  totalPayment: number;
  productCount?: number;
  addressId?: string;
  address: {
    line1Address: string;
    state: string;
    pincode: string;
    houseNo: string;
    street: string;
    landmark: string;
    locality: string;
    postalCode: string;
    type: string;
    location: ILocationCoordinates,
  },
  notes?: string;
  orderStatus: string;
  orderStatusName?: string;
  deliveryTimeSlot: IDeliveryTimeSlot;
  orderedDateTime: string;
  paymentType: string;
  paymentStatus: string;
  orderType: string;
  mealTime: string;
  kitchenId: string;
  userId: string;
  orderId: string;
  riderId?: string;
  cookMobile: string;
  riderName?: string;
  riderMobileNumber?: string;
  kitchenAddress?: {
    line1Address: string;
    state: string;
    pincode: string;
    houseNo: string;
    street: string;
    landmark: string;
    locality: string;
    postalCode: string;
    type: string;
  },
  riderAddress?: {
    line1Address: string;
    state: string;
    pincode: string;
    houseNo: string;
    street: string;
    landmark: string;
    locality: string;
    postalCode: string;
    type: string;
  },
  createdAt: string;
  lastStatusUpdated: string;
  kitchenName: string;
  customerName: string;
  customerMobile: string;
  cookName: string;
  trackingUrl?: string;
  orderDelayType: IOrderDelayType;
  couponId?: string;
  isFirstTimeCustomer?: boolean;
  deliveredOn: string;
  riderAssigningDelay?: number;
  isDeliveredOrderDelayed?: boolean;
  logisticsOrderId: string;
  isRiderAssigningDelayed?: boolean;
  tplVendorName: string;
  tplTrackingUrl?: string;
  preOrder?: boolean;
}

export interface IRating {
  createdAt: string;
  orderId: string;
  rating: number;
  ratingId: string;
  review: string;
  revieweeId: string;
  revieweeType: 'kitchen' | 'rider' | 'item';
  reviewerId: string;
  reviewerType: 'customer' | 'rider';
  updatedAt: string;
}

export interface IOrderLog {
  createdAt: string;
  orderId: string;
  orderStatus: string;
  updatedAt: string;
  updatedBy: string;
  orderStatusName?: string,
  __v: number;
  _id: string;
}

export interface IOrderDetails extends IOrderResponse {
  billedAmount: number;
  cgst: number;
  createdAt: string;
  customerLocation?: ILocationCoordinates;
  customerRatings: IRating[];
  deliveryCharge: number;
  differentlyAbledType: string;
  foodCuisineStyle: string[];
  itemDetails: {
    itemId: string;
    itemCategory: string;
    itemName: string;
    quantity: number;
    price: number;
  }[],
  kitchenLocation?: ILocationCoordinates;
  netAmount: number;
  orderLogs: IOrderLog[];
  riderLocation?: ILocationCoordinates;
  roundOff: number;
  sgst: number;
  revisedSgst?: 0,
  revisedCgst?: 0,
  discountedItemTotal?: 0,
  discountedNetAmount?: 0,
  razorPayPaymentId?: string;
  specialInstructionAcknowledged?: boolean;
  riderTipAmount?: number;
  couponDetails?: {
    _id: string;
    termsAndCondition: string;
    detailedDescription: string;
    couponId: string;
    couponCode: string;
    discountType: string;
    discount: number;
    savingsOnDiscount: number;
    couponName: string,
    description: string;
    isActive: boolean,
    constraints: [
      {
        maxRedemption: number;
      }
    ],
  },
  distanceStats: Partial<IDistanceStats>,
  igst?: 0,
  revisedIgst?: 0,
  orderedByAdmin: string;
  isFirstTimeCustomer?: boolean;
  logisticsOrderId: string;
  tplVendorName: string;
  tplTrackingUrl?: string;
}

export interface ILiveOrdersMetrics {
  normalOrders: number,
  middleOrders: number,
  issueOrders: number,
  assignmentIssueOrders: number,
}

export interface ICustomerOrderedFirstTime {
  userId: string,
  orderId: string,
  isFirstTimeCustomer?: boolean,
}
export interface ICustomerOrderedFirstTimePayload {
  listOfOrdersToCheck: Array<ICustomerOrderedFirstTime>,
}

export interface ICancelTPLPickedUpOrderPayload {
  reason: string;
  contactNumber?: string;
  logisticsOrderId: string;
  orderId: string;
}

export interface ITplOrderCancellationResponse {
  code: number;
  message: string;
}

export const { envName } = environment;

export interface IErrorLogs {
  logisticOrderId: string;
  errorMessage: string;
  createdBy: {
    entityId: string;
    entityName: string;
    entityType: string;
  },
  expireAt: string;
  createdAt: string;
  updatedAt: string;
}
export interface ITplErrorLogs {
  errorLogs: IErrorLogs[];
}

export interface ITplRiderErrorLogs {
  data: ITplErrorLogs,
  logsCount: number,
}

export interface ITodaysOrderAddress {
  line1Address: string;
  line2Address: string;
  line3Address: string;
  city: string;
  state: string;
  nearestLocation: string;
  pincode: string;
  location: ILocationCoordinates;
}

export interface IAddress {
  addressId: string;
  customerId: string;
  houseNo: string;
  street: string;
  locality: string;
  landmark: string;
  city: string;
  state: string;
  postalCode: string;
  mapAddress: string;
  isFormattedAddress: boolean;
  addressType: string;
  location: ILocationCoordinates;
  isLastUsed: boolean;
  isArchive: boolean;
  deliveryInstructions?: string;
  _id?: string;
  receiverDetails: IReceiverDetails;
}

export interface IEntity {
  entityName: string;
  entityType: string;
  entityId: string;
}

export interface INotificationLog {
  delayNotificationLogId: string;
  callMadeToCustomer: boolean;
  customerResponse: string;
  eventType: DELAY_NOTIFICATION_EVENT_TYPE;
  notificationMessageByTemplate: string;
  notificationMessage: string;
  couponName: string;
  referenceId: string;
  remarks: string;
  createdAt: string;
  createdBy: IEntity;
  updatedAt: string;
}

export interface ITodaysOrderInterface {
  referenceId: string,
  customerId: string,
  customerName: string,
  deliveryTimeSlot: IDeliveryTimeSlot,
  orderType: string,
  mealTime: string,
  parentZoneId: string,
  subZoneId: string,
  purchaseOrderId: string,
  paymentMode: string,
  isProductExists: boolean,
  isOrderNoteExists: boolean,
  riderName: string,
  riderPhoneNumber: string,
  vendorName: string,
  riderType: string,
  couponId: string,
  customerAddress: IAddress,
  kitchenAddress: ITodaysOrderAddress,
  riderAddress: ITodaysOrderAddress,
  orderStatus: string,
  kitchenPhoneNumber?: string;
  kitchenAltPhoneNumber?: string;
  customerPhoneNumber: string,
  kitchenName: string,
  riderAssigningDelay?: number;
  isRiderAssigningDelayed: boolean,
  orderDelayType: {
    delayType: string,
    delayTime: number;
  },
  createdAt: string;
  preOrder: boolean;
  parentZoneName: string;
  cusineTag: string;
  tplTrackingUrl?: string;
  trackingUrl?: string;
  isDeliveredOrderDelayed?: boolean;
  tplOrderId?: string;
  chefOrderId?: string;
  logisticOrderId?: string;
  isFirstTimeCustomer?: boolean;
  deliveredOn?: string;
  riderId: string;
  orderStatusName?: string;
  kitchenId: string;
  isChefOrderAcknowledged: boolean;
  cookId?: string;
  pickUpDateTime?: string;
  simplifiedOrderId?: string;
  productType?: string;
  brandName?: string;
  relatedOrderCount?: number;
  isLastMileDistanceDifferenceMoreThan50Percent?: boolean;
  isAssignmentIssueOrder?: boolean;
  satelliteStoreId?: string;
  satelliteStoreName?: string;
  satelliteStoreContactName?: string;
  satelliteStoreContactNumber?: string;
  satelliteStoreAddress: string;
  isHighValueOrder?: boolean;
  delayNotificationLogs: Array<INotificationLog>;
  isFoodReady?: boolean;
  deliveryTimeSlotMessage?: string;
  isPreOrder?: boolean;
}

// ------------------------------

export interface IPurchaseOrderDetailsCustomerAddressLocation {
  type: string;
  coordinates: Array<number>;
}
export interface IReceiverDetails {
  name: string;
  phoneNumber: string;
}

export interface IPurchaseOrderDetailsCustomerAddress {
  houseNo?: string;
  street?: string;
  locality?: string;
  landmark?: string;
  city?: string;
  state?: string;
  postalCode?: string;
  mapAddress?: string;
  addressType?: string;
  location: IPurchaseOrderDetailsCustomerAddressLocation;
  deliveryInstructions?: string;
  receiverDetails?: IReceiverDetails;
}

export interface IOrderLogsCreatedBy {
  entityId: string;
  entityType: string;
  entityName: string;
}

export interface IPurchaseOrderDetailsIPurchaseOrderDetails {
  [key: string]: {
    itemName: string;
    quantity: number;
    price: number;
    itemCategory: string;
    foodType: string;
    revenueSharingPercentage?: number;
  };
}

export interface IProductDetails {
  [key: string]: {
    productId?: string,
    productName?: string;
    foodType?: string;
    sku?: number;
    kitchenId?: string;
    price?: number;
    skuUOM?: string;
    quantity?: number;
    product?: number;
    packingCharge?: number;
  };
}
export interface IPurchaseOrderDetails {
  customerName: string;
  customerPhoneNumber: string;
  customerAddress: IPurchaseOrderDetailsCustomerAddress,
  parentZoneName: string;
  subZoneName: string;
  mealTime: string;
  orderType: string;
  deliveryTimeSlot: IDeliveryTimeSlotDateAndTime,
  orderedDateTime: string;
  purchaseOrderId: string;
  referenceId: string;
  customerId: string;
  orderStatus: string;
  paymentId: string;
  createdBy: IOrderLogsCreatedBy,
  itemDetails: IPurchaseOrderDetailsIPurchaseOrderDetails,
  kitchenName: string;
  kitchenDetail?: string;
  specialInstructions?: string;
  specialInstructionAcknowledged?: string;
  cancellationReason?: string;
  deliveryInstructions?: string;
  productDetails?: IProductDetails;
  simplifiedOrderId: string;
}

export interface IDeliveryTimeSlotDateAndTime {
  date: string;
  time: string;
}

export interface IPurchaseOrderItemDetails {
  itemId: string;
  quantity: number;
  price: number;
  itemName: string;
  itemCategory: string;
  foodType: string;
}

export interface ICreatedOrUpdatedBy {
  entityId: string;
  entityName: string;
  entityType: number;
}

export interface IPurchaseOrder {
  createdAt: string;
  purchaseOrderId: string;
  referenceId: string;
  billDetails?: {
    couponId: string;
  };
  customerId: string;
  customerName: string;
  orderStatus: string;
  deliveryTimeSlot: IDeliveryTimeSlotDateAndTime;
  orderType: string;
  mealTime: string;
  itemDetails: Record<string, Record<string, IPurchaseOrderItemDetails>>;
  paymentId?: string;
  paymentStatus?: string;
  createdBy: ICreatedOrUpdatedBy;
  amount: number;
  mode: string;
  couponId: string;
  isOrderNoteExists: boolean;
  isProductExists: boolean;
  kitchenName: string;
  customerRatings: IRating[];
  _id?: string;
  cancellationReason?: string;
  subZoneId: string;
  parentZoneId: string;
  class?: string;
  ratingStarAssetLink?: string;
  orderStatusName?: string;
  isFirstTimeCustomer?: boolean;
  simplifiedOrderId?: string;
  productType?: string;
  brandName?: string;
  relatedOrderCount?: number;
  isLastMileDistanceDifferenceMoreThan50Percent?: boolean;
}

interface IPurchaseOrderInfo {
  data: {
    orders: IPurchaseOrder[],
    totalOrders: number,
  };
}

export interface IBillPaymentDetails {
  netAmount?: number;
  packagingCharge?: number;
  sgst?: number;
  cgst?: number;
  igst?: number;
  roundOff?: number;
  billedAmount?: number;
  revisedSgst?: number;
  revisedCgst?: number;
  revisedIgst?: number;
  revisedRoundOff?: number;
  deliveryTipAmount?: number;
  deliveryCharge?: number | null;
  couponId?: string;
  couponName?: string;
  couponCode?: string;
  savingsOnDiscount?: number;
}

export interface IPaymentDetails {
  email?: string;
  mobile: string;
  paymentType?: string;
  currency?: string;
  mode: string;
  billDetails?: IBillPaymentDetails;
  amount: number;
  status?: string;
  paymentId?: string;
  gatewayId?: string;
  gatewayOrderId?: string;
  customerName?: string;
  isPaid?: boolean;
}

export interface IIPurchaseOrderDetailsDataOrderLogs {
  purchaseOrderId: string;
  orderStatus: string;
  createdBy: IOrderLogsCreatedBy;
  createdAt: string;
  updatedAt: string;
}

export interface IOrderNotes {
  notesId: string;
  orderId: string;
  referenceId: string;
  note: string;
  createdAt: string;
  updatedAt: string;
  createdBy: IOrderLogsCreatedBy;
}

export interface ITransaction {
  gatewayTxnId?: string;
  paymentFailedReason?: string;
  txnId?: string;
  txnMethod?: string;
}

export enum RzpRefundSpeedEnum {
  NORMAL = 'normal',
  OPTIMUM = 'optimum'
}

export enum RefundStatusEnum {
  REFUND_CREATED = 'created',
  REFUND_FAILED = 'failed',
  REFUND_PROCESSED = 'processed',
}

export interface IRefundCreatedBy {
  entityId: string;
  entityType: string;
  entityName: string;
}

export interface IPaymentRefunds {
  refundId?: string;
  paymentId: string;
  txnId: string;
  refundType: RzpRefundSpeedEnum;
  gatewayRefundId?: string;
  refundAmount: number;
  refundReason?: string;
  refundStatus?: RefundStatusEnum;
  gatewayResponse?: object;
  createdBy?: IRefundCreatedBy;
  createdAt: string;
}
export interface IPurchaseOrderDetailsData {
  orderDetails: IPurchaseOrderDetails;
  payment: IPaymentDetails;
  orderLogs: Array<IIPurchaseOrderDetailsDataOrderLogs>;
  orderNotes: Array<IOrderNotes>;
  ratings: Array<IRating>;
  txn?: Array<ITransaction>;
  refundLogs?: Array<IPaymentRefunds>;
}

// ------- Cook Details
export interface ILocation {
  type: string;
  coordinates: Array<number>;
}
export interface IKitchenAddress {
  line1Address: string;
  line2Address: string;
  line3Address: string;
  pincode: string;
  city: string;
  state: string;
  nearestLocation: string;
  location: ILocation;
}
export interface ICookOrderDetails {
  cookId: string;
  cookName: string;
  cookMobileNumber: string;
  cookAltMobileNumber: string;
  specialInstructions: null;
  itemDetails: IPurchaseOrderDetailsIPurchaseOrderDetails;
  orderStatus: string;
  orderType: string;
  mealTime: string;
  orderedDateTime: string;
  deliveryTimeSlot: IDeliveryTimeSlotDateAndTime;
  parentZoneName: string;
  parentZoneId: string;
  subZoneName: string;
  subZoneId: string;
  createdBy: IOrderLogsCreatedBy;
  chefOrderId: string;
  referenceId: string;
  kitchenName?: string;
  specialInstructionAcknowledged?: boolean;
  kitchenAddress?: IKitchenAddress;
  cancelOrderDescription?: string;
  productDetails?: IProductDetails;
  simplifiedOrderId: string;
  relatedChefOrderIds?: Array<string>;
}

export interface ICookPayoutDetails {
  netAmount: number;
  totalPayment: number;
  couponId: string;
  discount: number;
  couponName: string;
  couponCode: string;
  couponCreatedFor: string;
}

export interface ICookOrderLogs {
  _id: string;
  chefOrderId: string;
  orderStatus: string;
  createdBy: IOrderLogsCreatedBy;
  createdAt: string;
  updatedAt: string;
  __v: number;
}

export interface ICookOrderNotes {
  _id: string;
  notesId: string;
  orderId: string;
  referenceId: string;
  note: string;
  createdAt: string;
  updatedAt: string;
  createdBy: IOrderLogsCreatedBy;
  __v: number;
}

export interface ISatelliteStoreDetails {
  satelliteStoreAddress: string;
  satelliteStoreContactName: string;
  satelliteStoreContactNumber: string;
  satelliteStoreId: string;
  satelliteStoreName: string;
}
export interface ICookOrderDetailsData {
  orderDetails: ICookOrderDetails;
  payoutDetails: ICookPayoutDetails;
  orderLogs: ICookOrderLogs[];
  orderNotes: ICookOrderNotes[];
  satelliteStoreDetails?: ISatelliteStoreDetails;
}

// -------- Rider

export interface IPickUpOrDropDetails {
  contactPersonName: string;
  contactPersonNumber: string;
  address: string,
  location: ILocation;
}
export interface IRiderOrderDetails {
  logisticOrderId?: string;
  referenceId?: string;
  orderType?: string;
  orderStatus?: string;
  mealTime?: string;
  pickUpDetails: IPickUpOrDropDetails;
  dropDetails: IPickUpOrDropDetails;
  deliveryTip?: number;
  deliveryTimeSlot?: IDeliveryTimeSlotDateAndTime;
  deliveryInstructions?: string;
  amountToBeCollected?: number;
  paymentMode?: string;
  tplOrderId?: string;
  riderType?: string;
  riderId?: string;
  createdBy?: IOrderLogsCreatedBy;
  parentZoneId?: string;
  parentZoneName?: string;
  subZoneId?: string;
  subZoneName?: string;
  riderName?: string,
  vendorName?: string,
  riderMobileNumber?: string,
  firstMileDistance?: number;
  lastMileDistance?: number;
  tplTrackingUrl?: string;
}

export interface IRiderOrderLogs {
  _id: string;
  logisticOrderId: string;
  orderStatus: string;
  createdBy: IOrderLogsCreatedBy;
  createdAt: string;
  updatedAt: string;
  distance?: number;
  duration?: number;
  __v: number;
}

export interface ITPLOrderRiderOrderDetailsData {
  parentZoneName: string;
  radiusInKms: number;
  firstMileDistance: number;
  lastMileDistance: number;
  isTplPreferable: boolean;
}

export interface IRiderOrderDetailsData {
  orderDetails: IRiderOrderDetails;
  orderLogs: IRiderOrderLogs[];
  tplOrderLogs: any;
  tplOrder?: ITPLOrderRiderOrderDetailsData;
  orderNotes?: ICookOrderNotes[];
}
export interface ILiveTrackingOrderDetailsData {
  firstMileDistance: number | null;
  lastMileDistance: number | null;
  riderLocation: Array<number> | null;
  kitchenLocation: Array<number> | null;
  customerLocation: Array<number> | null;
}

export interface IListOfOrdersToCheck {
  orderId: string;
  userId: string;
  purchaseOrderId: string;
  customerId: string;
  isFirstTimeCustomer?: boolean;
}

export interface IFirstTimeOrderPayload {
  purchaseOrderId: string;
  customerId: string;
}

export interface ITodaysOrderV2 {
  data: {
    orders: ITodaysOrderInterface[],
    totalOrdersCount: number,
  };
}

export interface IChefOrderItemDetails {
  itemId: string;
  quantity: number;
  price: number;
  itemName: string;
  itemCategory: string;
  foodType: string;
  revenueSharingPercentage: number;
  description?: string;
}

export enum OrderTypes {
  NOW = 'now',
  PREORDER = 'preOrder',
  PREBOOKING = 'preBooking',
  GET_IT_IMMEDIATELY = 'getItImmediately',
}

export enum MealTimes {
  BREAKFAST = 'breakfast',
  LUNCH = 'lunch',
  SNACKS = 'snacks',
  DINNER = 'dinner',
}

export interface IChefOrder {
  referenceId: string;
  chefOrderId: string;
  kitchenId: string;
  orderStatus: string;
  orderType: OrderTypes;
  mealTime: MealTimes;
  createdAt: string;
  orderedDateTime: Date;
  deliveryTimeSlot: IDeliveryTimeSlotDateAndTime,
  itemDetails: Record<string, IChefOrderItemDetails>;
  netAmount: number;
  totalPayment?: number;
  couponId?: string;
  discount?: number;
  parentZoneId: string;
  subZoneId: string;
  specialInstructions?: string;
  specialInstructionAcknowledged?: boolean;
  isOrderNoteExists: boolean;
  cookName: string;
  isProductExists: boolean;
  kitchenRating: IRating;
  cancelOrderDescription?: string;
  createdBy: ICreatedOrUpdatedBy;
  class?: string;
  orderStatusLabel?: string;
  ratingStarAssetLink?: string;
  kitchenName: string;
  simplifiedOrderId?: string;
  productType?: string;
  brandName?: string;
  relatedOrderCount?: number;
  isLastMileDistanceDifferenceMoreThan50Percent?: boolean;
}

interface IChefOrderDetails {
  data: {
    chefOrders: IChefOrder[],
    chefOrdersCount: number,
  };
}

export interface IRatings {
  createdAt: string;
  orderId: string;
  rating: number;
  ratingId: string;
  review: string;
  revieweeId: string;
  revieweeType: 'kitchen' | 'rider' | 'item' | '3pl-rider';
  reviewerId: string;
  reviewerType: 'customer' | 'rider';
  updatedAt: string;
}

export enum LogisticOrderRiderType {
  IN_HOUSE = 'inHouse',
  OTHERS = 'others',
}

export interface ILogisticOrderDetails {
  logisticOrderId: string,
  referenceId: string,
  orderType: OrderTypes,
  orderStatus: string,
  mealTime: MealTimes,
  pickUpDetails: IPickUpOrDropDetails,
  dropDetails: IPickUpOrDropDetails,
  deliveryTip: number,
  deliveryTimeSlot: IDeliveryTimeSlot,
  deliveryInstructions?: string;
  amountToBeCollected: number,
  paymentMode: string,
  tplOrderId?: string,
  isOrderNoteExists: boolean,
  riderRating: IRatings;
  riderType: LogisticOrderRiderType,
  riderId: string,
  riderName: string,
  createdBy: ICreatedOrUpdatedBy,
  isProductExists: boolean,
  parentZoneId: string,
  subZoneId: string,
  createdAt: string,
  updatedAt: string,
  __v: number,
  firstMileDistance: number,
  lastMileDistance: number,
  orderStatusLabel?: string;
  ratingStarAssetLink?: string;
  simplifiedOrderId?: string;
  productType?: string;
  brandName?: string;
  relatedOrderCount?: number;
  isLastMileDistanceDifferenceMoreThan50Percent?: boolean;
}

export interface ILogisticOrderResponse {
  data: {
    logisticOrders: ILogisticOrderDetails[],
    logisticOrdersCount: number;
  };
}

export interface IDelayNotificationPayload {
  templateId?: string,
  notificationMessage?: string,
  remarks?: string,
  callMadeToCustomer?: boolean,
  customerResponse?: CALL_RESPONSE_TYPE,
  eventType?: DELAY_NOTIFICATION_EVENT_TYPE;
}

@Injectable()
export class OrderApiService extends RestApiService {
  protected override baseApiUrl = environment.config['ADMIN_SERVICE_URI'];

  protected cookBaseApiUri = environment.config['KITCHEN_SERVICE_URI'];

  protected customerBaseApiUri = environment.config['CUSTOMER_SERVICE_URI'];

  protected cookApiUrl = environment.config['COOK_SERVICE_URI'];

  protected riderApiUrl = environment.config['LOGISTIC_SERVICE_URI'];

  protected orderServiceApiUrl = environment.config['ORDER_SERVICE_URI'];

  private cookServicePath = `${this.cookBaseApiUri}/orders`;

  private configServiceBaseApiUrl = environment.config['CONFIG_SERVICE_URI'];

  private servicePath = `${this.baseApiUrl}/control-tower/orders`;

  private customerPath = `${this.customerBaseApiUri}/orders/detail/reference`;

  private cookPath = `${this.cookApiUrl}/chef-orders/detail/reference`;

  private riderPath = `${this.riderApiUrl}/logistic-orders/detail/reference`;

  private purchaseOrdersPath = `${this.customerBaseApiUri}/orders/purchase-details`;

  protected chefOrdersBaseApiUrl = environment.config['COOK_SERVICE_URI'];

  private chefOrdersServicePath = `${this.chefOrdersBaseApiUrl}/common/chef-orders`;

  private riderOrdersServicePath = `${this.riderApiUrl}/common/logistic-orders`;

  private delayNotificationServicePath = `${this.baseApiUrl}/todays-order/send-delay-notification`;

  constructor(http: HttpClientService) {
    super(http);
    this.authenticatedRoute = true;
  }

  async getKitchenDetails(id: string) {
    const apiPath = `${this.cookBaseApiUri}/kitchens/${id}`;
    return this.httpGet(apiPath);
  }

  async getParentZoneById(payload: { parentZoneId: string[]; })
    : Promise<{ data: ParentZonalDetail[]; }> {
    const apiPath = `${this.baseApiUrl}/zonal-details/parent-zone/get-info-by-ids`;
    return this.httpPost(apiPath, payload);
  }

  async getLiveOrdersMetrics(queryParams?: Record<any, (string | number)>):
    Promise<ILiveOrdersMetrics> {
    const searchParams = new URLSearchParams();
    if (queryParams) {
      if (queryParams['orderDelayType']) {
        searchParams.set('orderDelayType', String(queryParams['orderDelayType']));
      }
      if (Array.isArray(queryParams['parentZoneId'])) {
        queryParams['parentZoneId'].forEach((item) => {
          searchParams.append('parentZoneId', item);
        });
      } else if (queryParams['parentZoneId']) {
        searchParams.append('parentZoneId', String(queryParams['parentZoneId']));
      }
      if (queryParams['subZoneId']) {
        searchParams.set('subZoneId', String(queryParams['subZoneId']));
      }
    }
    const apiPath = `${this.servicePath}/live-metrics?${searchParams.toString()}`;
    return this.httpGet(apiPath, {
      preferLatestCall: true,
    });
  }

  async getLiveOrdersMetricsV2(queryParams?: Record<any, (string | number)>):
    Promise<{ data: ILiveOrdersMetrics; }> {
    const searchParams = new URLSearchParams();
    if (queryParams) {
      if (queryParams['orderDelayType']) {
        searchParams.set('orderDelayType', String(queryParams['orderDelayType']));
      }
      if (Array.isArray(queryParams['parentZoneId'])) {
        queryParams['parentZoneId'].forEach((item) => {
          searchParams.append('parentZoneId', item);
        });
      } else if (queryParams['parentZoneId']) {
        searchParams.append('parentZoneId', String(queryParams['parentZoneId']));
      }
      if (queryParams['subZoneId']) {
        searchParams.set('subZoneId', String(queryParams['subZoneId']));
      }
      if (queryParams['orderCategory']) {
        searchParams.set('orderCategory', String(queryParams['orderCategory']));
      }
    }
    const apiPath = `${this.customerBaseApiUri}/common/today-orders/live-metrics?${searchParams.toString()}`;
    return this.httpGet(apiPath, {
      preferLatestCall: true,
    });
  }

  async getAll(queryParams?: Record<any, (string | number)>): Promise<{
    orders: IOrderResponse[],
    totalOrders: number,
  }> {
    const searchParams = new URLSearchParams();
    if (queryParams) {
      if (queryParams['orderDelayType']) {
        searchParams.set('orderDelayType', String(queryParams['orderDelayType']));
      }
      if (queryParams['limit']) {
        searchParams.set('limit', String(queryParams['limit']));
      }
      if (queryParams['skip']) {
        searchParams.set('skip', String(queryParams['skip']));
      }
      if (Array.isArray(queryParams['parentZoneId'])) {
        queryParams['parentZoneId'].forEach((item) => {
          searchParams.append('parentZoneId', item);
        });
      } else if (queryParams['parentZoneId']) {
        searchParams.append('parentZoneId', String(queryParams['parentZoneId']));
      }
      if (queryParams['subZoneId']) {
        searchParams.set('subZoneId', String(queryParams['subZoneId']));
      }
      if (queryParams['search']) {
        searchParams.set('search', String(queryParams['search']));
      }
      if (queryParams['riderId']) {
        searchParams.set('riderId', String(queryParams['riderId']));
      }
      if (queryParams['isViewAllOrders'] !== undefined) {
        searchParams.set('isViewAllOrders', String(queryParams['isViewAllOrders']));
      }
      if (queryParams['userId']) {
        searchParams.set('userId', String(queryParams['userId']));
      }
      if (queryParams['kitchenId']) {
        searchParams.set('kitchenId', String(queryParams['kitchenId']));
      }
    }
    const apiPath = `${this.servicePath}?${searchParams.toString()}`;
    return this.httpGet(apiPath, {
      preferLatestCall: true,
    });
  }

  async getAllLiveOrders(queryParams?: Record<any, (string | number)>): Promise<{
    orders: IOrderResponse[],
    totalOrders: number,
  }> {
    const searchParams = new URLSearchParams();
    if (queryParams) {
      if (queryParams['orderDelayType']) {
        searchParams.set('orderDelayType', String(queryParams['orderDelayType']));
      }
      if (queryParams['limit']) {
        searchParams.set('limit', String(queryParams['limit']));
      }
      if (queryParams['skip']) {
        searchParams.set('skip', String(queryParams['skip']));
      }
      if (Array.isArray(queryParams['parentZoneId'])) {
        queryParams['parentZoneId'].forEach((item) => {
          searchParams.append('parentZoneId', item);
        });
      } else if (queryParams['parentZoneId']) {
        searchParams.append('parentZoneId', String(queryParams['parentZoneId']));
      }
      if (queryParams['subZoneId']) {
        searchParams.set('subZoneId', String(queryParams['subZoneId']));
      }
      if (queryParams['search']) {
        searchParams.set('search', String(queryParams['search']));
      }
      if (queryParams['riderId']) {
        searchParams.set('riderId', String(queryParams['riderId']));
      }
      if (queryParams['isViewAllOrders'] !== undefined) {
        searchParams.set('isViewAllOrders', String(queryParams['isViewAllOrders']));
      }
      if (queryParams['userId']) {
        searchParams.set('userId', String(queryParams['userId']));
      }
      if (queryParams['kitchenId']) {
        searchParams.set('kitchenId', String(queryParams['kitchenId']));
      }
      if (queryParams['sortField'] && queryParams['sortOrder']) {
        searchParams.set('sortField', String(queryParams['sortField']));
      }
      if (queryParams['sortOrder']) {
        searchParams.set('sortOrder', String(queryParams['sortOrder']));
      }
    }
    const apiPath = `${this.servicePath}/today?${searchParams.toString()}`;
    return this.httpGet(apiPath, {
      preferLatestCall: true,
    });
  }

  async getAllLiveOrdersV2(queryParams?: Record<any, (string | number)>): Promise<ITodaysOrderV2> {
    const searchParams = new URLSearchParams();
    if (queryParams) {
      if (queryParams['delayType']) {
        searchParams.set('delayType', String(queryParams['delayType']));
      }
      if (queryParams['limit']) {
        searchParams.set('limit', String(queryParams['limit']));
      }
      if (queryParams['skip'] || queryParams['skip'] === 0) {
        searchParams.set('skip', String(queryParams['skip']));
      }
      if (Array.isArray(queryParams['parentZoneId'])) {
        queryParams['parentZoneId'].forEach((item) => {
          searchParams.append('parentZoneId', item);
        });
      } else if (queryParams['parentZoneId']) {
        searchParams.append('parentZoneId', String(queryParams['parentZoneId']));
      }
      if (queryParams['subZoneId']) {
        searchParams.set('subZoneId', String(queryParams['subZoneId']));
      }
      if (queryParams['search']) {
        searchParams.set('search', String(queryParams['search']));
      }
      if (queryParams['riderId']) {
        searchParams.set('riderId', String(queryParams['riderId']));
      }
      if (queryParams['isViewAllOrders'] !== undefined) {
        searchParams.set('isViewAllOrders', String(queryParams['isViewAllOrders']));
      }
      if (queryParams['userId']) {
        searchParams.set('customerId', String(queryParams['userId']));
      }
      if (queryParams['kitchenId']) {
        searchParams.set('kitchenId', String(queryParams['kitchenId']));
      }
      if (queryParams['sortField'] && queryParams['sortOrder']) {
        searchParams.set('sortField', String(queryParams['sortField']));
      }
      if (queryParams['sortOrder']) {
        searchParams.set('sortOrder', String(queryParams['sortOrder']));
      }
      if (queryParams['orderCategory']) {
        searchParams.set('orderCategory', String(queryParams['orderCategory']));
      }
      if (queryParams['isPreOrder']) {
        searchParams.set('isPreOrder', String(queryParams['isPreOrder']));
      }
      if (queryParams['orderTabType']) {
        searchParams.set('orderTabType', String(queryParams['orderTabType']));
      }
      if (Array.isArray(queryParams['orderStatus'])) {
        queryParams['orderStatus'].forEach((item) => {
          searchParams.append('orderStatus', item);
        });
      } else if (queryParams['orderStatus']) {
        searchParams.append('orderStatus', String(queryParams['orderStatus']));
      }

      if (Array.isArray(queryParams['mealTime'])) {
        queryParams['mealTime'].forEach((item) => {
          searchParams.append('mealTime', item);
        });
      } else if (queryParams['mealTime']) {
        searchParams.append('mealTime', String(queryParams['mealTime']));
      }
      if (Array.isArray(queryParams['orderType'])) {
        queryParams['orderType'].forEach((item) => {
          searchParams.append('orderType', item);
        });
      }
    }
    const apiPath = `${this.customerBaseApiUri}/common/today-orders?${searchParams.toString()}`;
    return this.httpGet(apiPath, {
      preferLatestCall: true,
    });
  }

  async getPurchaseOrders(queryParams?: Record<any, (string | number)>): Promise<IPurchaseOrderInfo> {
    const searchParams = new URLSearchParams();
    if (queryParams) {
      if (queryParams['kitchenId']) {
        searchParams.set('kitchenId', String(queryParams['kitchenId']));
      }
      if (queryParams['limit']) {
        searchParams.set('limit', String(queryParams['limit']));
      }
      if (queryParams['skip'] !== undefined) {
        searchParams.set('skip', String(queryParams['skip']));
      }
      if (queryParams['mealTime'] !== undefined) {
        if (Array.isArray(queryParams['mealTime'])) {
          queryParams['mealTime'].forEach((item) => {
            searchParams.append('mealTime', item);
          });
        }
      }
      if (queryParams?.['search']) {
        searchParams.set('search', String(queryParams['search']));
      }
      if (Array.isArray(queryParams['orderStatus'])) {
        queryParams['orderStatus'].forEach((item) => {
          searchParams.append('orderStatus', item);
        });
      }
      if (queryParams['orderCategory']) {
        searchParams.set('orderCategory', String(queryParams['orderCategory']));
      }
      if (Array.isArray(queryParams['orderType'])) {
        queryParams['orderType'].forEach((item) => {
          searchParams.append('orderType', item);
        });
      }
      if (queryParams['sendAllOrders'] !== undefined) {
        searchParams.set('sendAllOrders', String(queryParams['sendAllOrders']));
      }
      if (Array.isArray(queryParams['parentZoneId'])) {
        queryParams['parentZoneId'].forEach((item) => {
          searchParams.append('parentZoneId', item);
        });
      } else if (queryParams['parentZoneId'] !== undefined) {
        searchParams.append('parentZoneId', String(queryParams['parentZoneId']));
      }
      if (queryParams['subZoneId'] !== undefined) {
        searchParams.set('subZoneId', String(queryParams['subZoneId']));
      }
      if (queryParams['startDate'] !== undefined) {
        searchParams.set('startDate', String(queryParams['startDate']));
      }
      if (queryParams['endDate'] !== undefined) {
        searchParams.set('endDate', String(queryParams['endDate']));
      }
      if (queryParams['adminOrders'] !== undefined) {
        searchParams.set('adminOrders', String(queryParams['adminOrders']));
      }
      if (queryParams['customerId']) {
        searchParams.set('customerId', String(queryParams['customerId']));
      }
    }

    const apiPath = `${this.purchaseOrdersPath}?${searchParams.toString()}`;
    return this.httpGet(apiPath, {
      preferLatestCall: true,
    });
  }

  async getChefOrders(queryParams?: Record<any, (string | number | boolean)>): Promise<IChefOrderDetails> {
    const searchParams = new URLSearchParams();
    if (queryParams) {
      if (queryParams['kitchenId']) {
        searchParams.set('kitchenId', String(queryParams['kitchenId']));
      }
      if (queryParams['limit']) {
        searchParams.set('limit', String(queryParams['limit']));
      }
      if (queryParams['skip'] !== undefined) {
        searchParams.set('skip', String(queryParams['skip']));
      }
      if (queryParams['mealTime'] !== undefined) {
        if (Array.isArray(queryParams['mealTime'])) {
          queryParams['mealTime'].forEach((item) => {
            searchParams.append('mealTime', item);
          });
        }
      }
      if (Array.isArray(queryParams['orderStatus'])) {
        queryParams['orderStatus'].forEach((item) => {
          searchParams.append('orderStatus', item);
        });
      }
      if (queryParams['orderCategory']) {
        searchParams.set('orderCategory', String(queryParams['orderCategory']));
      }
      if (Array.isArray(queryParams['orderType'])) {
        queryParams['orderType'].forEach((item) => {
          searchParams.append('orderType', item);
        });
      }
      if (queryParams['sendAllOrders'] !== undefined) {
        searchParams.set('sendAllOrders', String(queryParams['sendAllOrders']));
      }
      if (Array.isArray(queryParams['parentZoneId'])) {
        queryParams['parentZoneId'].forEach((item) => {
          searchParams.append('parentZoneId', item);
        });
      } else if (queryParams['parentZoneId'] !== undefined) {
        searchParams.append('parentZoneId', String(queryParams['parentZoneId']));
      }
      if (queryParams['subZoneId'] !== undefined) {
        searchParams.set('subZoneId', String(queryParams['subZoneId']));
      }
      if (queryParams['startDate'] !== undefined) {
        searchParams.set('startDate', String(queryParams['startDate']));
      }
      if (queryParams['endDate'] !== undefined) {
        searchParams.set('endDate', String(queryParams['endDate']));
      }
      if (queryParams?.['search']) {
        searchParams.set('search', String(queryParams['search']));
      }
    }

    const apiPath = `${this.chefOrdersServicePath}?${searchParams.toString()}`;
    return this.httpGet(apiPath, {
      preferLatestCall: true,
    });
  }

  async getAllLogisticOrders(queryParams: Record<any, (string | number)>)
    : Promise<ILogisticOrderResponse> {
    const searchParams = new URLSearchParams();
    if (queryParams) {
      if (queryParams['limit']) {
        searchParams.set('limit', String(queryParams['limit']));
      }
      if (queryParams['skip'] !== undefined) {
        searchParams.set('skip', String(queryParams['skip']));
      }
      if (queryParams['search'] !== undefined) {
        searchParams.set('search', String(queryParams['search']));
      }
      if (queryParams['startDate'] !== undefined) {
        searchParams.set('startDate', String(queryParams['startDate']));
      }
      if (queryParams['endDate'] !== undefined) {
        searchParams.set('endDate', String(queryParams['endDate']));
      }
      if (queryParams['mealTime'] !== undefined) {
        if (Array.isArray(queryParams['mealTime'])) {
          queryParams['mealTime'].forEach((item) => {
            searchParams.append('mealTime', item);
          });
        }
      }
      if (Array.isArray(queryParams['orderStatus'])) {
        queryParams['orderStatus'].forEach((item) => {
          searchParams.append('orderStatus', item);
        });
      }
      if (queryParams['orderCategory']) {
        searchParams.set('orderCategory', String(queryParams['orderCategory']));
      }
      if (Array.isArray(queryParams['orderType'])) {
        queryParams['orderType'].forEach((item) => {
          searchParams.append('orderType', item);
        });
      }
      if (Array.isArray(queryParams['riderType'])) {
        queryParams['riderType'].forEach((item) => {
          searchParams.append('riderType', item);
        });
      }
      if (queryParams['sendAllOrders'] !== undefined) {
        searchParams.set('sendAllOrders', String(queryParams['sendAllOrders']));
      }
      if (Array.isArray(queryParams['parentZoneId'])) {
        queryParams['parentZoneId'].forEach((item) => {
          searchParams.append('parentZoneId', item);
        });
      } else if (queryParams['parentZoneId'] !== undefined) {
        searchParams.append('parentZoneId', String(queryParams['parentZoneId']));
      }
      if (queryParams['subZoneId'] !== undefined) {
        searchParams.set('subZoneId', String(queryParams['subZoneId']));
      }
      if (queryParams['riderId']) {
        searchParams.set('riderId', String(queryParams['riderId']));
      }
    }
    const apiPath = `${this.riderOrdersServicePath}?${searchParams}`;
    return this.httpGet(apiPath, {
      preferLatestCall: true,
    });
  }

  async getDeliveredOrdersWithInThirtyMin(queryParams?: Record<any, (string | number)>): Promise<{
    orders: IOrderResponse[],
    totalOrders: number,
  }> {
    const searchParams = new URLSearchParams();
    if (queryParams) {
      if (queryParams['orderDelayType']) {
        searchParams.set('orderDelayType', String(queryParams['orderDelayType']));
      }
      if (queryParams['limit']) {
        searchParams.set('limit', String(queryParams['limit']));
      }
      if (queryParams['skip']) {
        searchParams.set('skip', String(queryParams['skip']));
      }
      if (Array.isArray(queryParams['parentZoneId'])) {
        queryParams['parentZoneId'].forEach((item) => {
          searchParams.append('parentZoneId', item);
        });
      } else if (queryParams['parentZoneId']) {
        searchParams.append('parentZoneId', String(queryParams['parentZoneId']));
      }
      if (queryParams['subZoneId']) {
        searchParams.set('subZoneId', String(queryParams['subZoneId']));
      }
      if (queryParams['search']) {
        searchParams.set('search', String(queryParams['search']));
      }
      if (queryParams['riderId']) {
        searchParams.set('riderId', String(queryParams['riderId']));
      }
      if (queryParams['isViewAllOrders']) {
        searchParams.set('isViewAllOrders', String(queryParams['isViewAllOrders']));
      }
    }
    const apiPath = `${this.servicePath}/recent/delivered?${searchParams.toString()}`;
    return this.httpGet(apiPath, {
      preferLatestCall: true,
    });
  }

  async getDeliveredOrders(queryParams?: Record<any, (string | number)>): Promise<{
    orders: IOrderResponse[],
    totalOrders: number,
  }> {
    const searchParams = new URLSearchParams();
    if (queryParams) {
      if (queryParams['limit']) {
        searchParams.set('limit', String(queryParams['limit']));
      }
      if (queryParams['skip']) {
        searchParams.set('skip', String(queryParams['skip']));
      }
      if (Array.isArray(queryParams['parentZoneId'])) {
        queryParams['parentZoneId'].forEach((item) => {
          searchParams.append('parentZoneId', item);
        });
      } else if (queryParams['parentZoneId']) {
        searchParams.append('parentZoneId', String(queryParams['parentZoneId']));
      }
      if (queryParams['subZoneId']) {
        searchParams.set('subZoneId', String(queryParams['subZoneId']));
      }
      if (queryParams['search']) {
        searchParams.set('search', String(queryParams['search']));
      }
      if (queryParams['orderCategory']) {
        searchParams.set('orderCategory', String(queryParams['orderCategory']));
      }
    }
    const apiPath = `${this.servicePath}/delivered/today?${searchParams.toString()}`;
    return this.httpGet(apiPath, {
      preferLatestCall: true,
    });
  }

  async getDeliveredOrdersV2(queryParams?: Record<any, (string | number)>): Promise<ITodaysOrderV2> {
    const searchParams = new URLSearchParams();
    if (queryParams) {
      if (queryParams['limit']) {
        searchParams.set('limit', String(queryParams['limit']));
      }
      if (queryParams['skip'] || queryParams['skip'] === 0) {
        searchParams.set('skip', String(queryParams['skip']));
      }
      if (Array.isArray(queryParams['parentZoneId'])) {
        queryParams['parentZoneId'].forEach((item) => {
          searchParams.append('parentZoneId', item);
        });
      } else if (queryParams['parentZoneId']) {
        searchParams.append('parentZoneId', String(queryParams['parentZoneId']));
      }
      if (queryParams['subZoneId']) {
        searchParams.set('subZoneId', String(queryParams['subZoneId']));
      }
      if (queryParams['search']) {
        searchParams.set('search', String(queryParams['search']));
      }
      if (queryParams['sortField'] && queryParams['sortOrder']) {
        searchParams.set('sortField', String(queryParams['sortField']));
      }
      if (queryParams['sortOrder']) {
        searchParams.set('sortOrder', String(queryParams['sortOrder']));
      }
      if (queryParams['orderCategory']) {
        searchParams.set('orderCategory', String(queryParams['orderCategory']));
      }
      if (queryParams['orderTabType']) {
        searchParams.set('orderTabType', String(queryParams['orderTabType']));
      }
    }
    const apiPath = `${this.customerBaseApiUri}/common/today-orders/recent/delivered?${searchParams.toString()}`;
    return this.httpGet(apiPath, {
      preferLatestCall: true,
    });
  }

  // async addNotes(orderId: string, notes?: string) {
  //   const payload = {
  //     notes,
  //   };
  //   const apiPath = `${this.cookServicePath}/add-notes/${orderId}`;
  //   return this.httpPatch(apiPath, payload);
  // }

  async assignRider({ orderId, riderId }: { orderId: string, riderId: string; }):
    Promise<IOrderResponse> {
    const apiPath = `${this.cookServicePath}/custom-rider-order/${riderId}`;
    return this.httpPut(apiPath, {
      orderId,
    });
  }

  async assignRiderV2({ referenceId, rider, logisticOrderId }: { rider: string, referenceId: string, logisticOrderId: string; }):
    Promise<IOrderResponse> {
    const logisticOrder = logisticOrderId ? `&logisticOrderId=${logisticOrderId}` : '';
    const apiPath = `${this.riderApiUrl}/logistic-orders/custom-rider-order/${rider}?referenceId=${referenceId}${logisticOrder}`;
    return this.httpPut(apiPath);
  }

  async findOne(orderId: string): Promise<
    IOrderDetails
  > {
    const apiPath = `${this.servicePath}/${orderId}`;
    return this.httpGet(apiPath);
  }

  async updateOrderStatus(id: string, orderStatus: string, cancelOrderDescription?: string) {
    const searchParams = new URLSearchParams();
    searchParams.set('orderStatus', orderStatus);
    const body: any = {};
    if (cancelOrderDescription) {
      body.cancelOrderDescription = cancelOrderDescription;
    }
    const apiPath = `${this.cookServicePath}/order-status/${id}?${searchParams.toString()}`;
    return this.httpPut(apiPath, body);
  }

  async fetchCustomerIsFirstTimeOrdered(payload: ICustomerOrderedFirstTimePayload): Promise<Array<ICustomerOrderedFirstTime>> {
    const body: ICustomerOrderedFirstTimePayload = payload;
    const apiPath = `${this.baseApiUrl}/orders/customers-first-order`;
    return this.httpPost(apiPath, body);
  }

  async cancelTplOrder(logisticsOrderId: string, vendorName: string): Promise<ITplOrderCancellationResponse> {
    const searchParams = new URLSearchParams();
    searchParams.set('vendorName', vendorName);
    const apiPath = `${this.baseApiUrl}/logistics/cancel-order/${logisticsOrderId}?${searchParams.toString()}`;
    return this.httpPut(apiPath);
  }

  async cancelTplOrderV2(logisticsOrderId: string): Promise<ITplOrderCancellationResponse> {
    const apiPath = `${this.riderApiUrl}/tpl-providers/cancel-order/logistic/${logisticsOrderId}`;
    return this.httpPut(apiPath);
  }

  async cancelTplPickedUpOrder(payload: ICancelTPLPickedUpOrderPayload, vendorName: string):
    Promise<ITplOrderCancellationResponse> {
    const searchParams = new URLSearchParams();
    searchParams.set('vendorName', vendorName);
    const apiPath = `${this.baseApiUrl}/logistics/cancel-assigned-order?${searchParams.toString()}`;
    return this.httpPost(apiPath, payload);
  }

  async fetch3plRiderErrorLogs(orderId: string, queryParams?: Record<any, (string | number)>)
    : Promise<{ data: ITplErrorLogs; }> {
    const searchParams = new URLSearchParams();
    if (queryParams) {
      if (queryParams['limit']) {
        searchParams.set('limit', String(queryParams['limit']));
      }
      if (queryParams['skip'] !== undefined) {
        searchParams.set('skip', String(queryParams['skip']));
      }
    }
    const apiPath = `${this.riderApiUrl}/tpl-providers/error-logs/logistics/${orderId}?${searchParams.toString()}`;
    return this.httpGet(apiPath);
  }

  async getOrderDetails(id: string) {
    const apiPath = `${this.customerPath}/${id}`;
    return this.httpGet(apiPath);
  }

  async getCookDetails(id: string, chefOrderId: string = '') {
    const searchParams = new URLSearchParams();
    if (chefOrderId) {
      searchParams.set('chefOrderId', String(chefOrderId));
    }
    const apiPath = `${this.cookPath}/${id}?${searchParams.toString()}`;
    return this.httpGet(apiPath);
  }

  async getRiderDetails(id: string) {
    const apiPath = `${this.riderPath}/${id}`;
    return this.httpGet(apiPath);
  }

  async getLiveTrackingDetail(id: string) {
    const apiPath = `${this.riderApiUrl}/logistic-orders/reference/${id}/live-tracking`;
    return this.httpGet(apiPath);
  }

  async addNotes(referenceId: string, note: string, orderId: string) {
    const payload = {
      note,
      referenceId,
      orderId,
    };
    const apiPath = `${this.orderServiceApiUrl}/order-notes`;
    return this.httpPost(apiPath, payload);
  }

  async readyForPickup(referenceId: string, chefOrderId: string) {
    const searchParams = new URLSearchParams();
    searchParams.set('orderStatus', 'readyForPickUp');
    const apiPath = `${this.cookApiUrl}/chef-orders/${chefOrderId}?${searchParams.toString()}`;
    return this.httpPut(apiPath);
  }

  async orderPreparing(referenceId: string) {
    const apiPath = `${this.chefOrdersServicePath}/seller-products/order-status/preparing/referenceId/${referenceId}`;
    return this.httpPut(apiPath);
  }

  async updateDeliveredStatus(referenceId: string, remarks: string, orderId: string) {
    const payload = {
      remarks,
    };
    const apiPath = `${this.riderApiUrl}/common/logistic-orders/delivered/${orderId}?referenceId=${referenceId}`;
    return this.httpPut(apiPath, payload);
  }

  async updateUnDeliveredStatus(referenceId: string, remarks: string, orderId: string) {
    const payload = {
      remarks,
    };
    const apiPath = `${this.riderApiUrl}/common/logistic-orders/undelivered/${orderId}?referenceId=${referenceId}`;
    return this.httpPut(apiPath, payload);
  }

  async updateCookInCompletedStatus(referenceId: string, remarks: string, orderId: string) {
    const payload = {
      remarks,
    };
    const apiPath = `${this.cookApiUrl}/common/chef-orders/${orderId}/incomplete?referenceId=${referenceId}`;
    return this.httpPut(apiPath, payload);
  }

  async updateCookPartiallyCompleteCompletedStatus(referenceId: string, remarks: string, orderId: string) {
    const payload = {
      remarks,
    };
    const apiPath = `${this.cookApiUrl}/common/chef-orders/${orderId}/partially-full-filled?referenceId=${referenceId}`;
    return this.httpPut(apiPath, payload);
  }

  async updateRiderInCompletedStatus(referenceId: string, remarks: string, orderId: string) {
    const payload = {
      remarks,
    };
    const apiPath = `${this.riderApiUrl}/common/logistic-orders/incomplete/${orderId}?referenceId=${referenceId}`;
    return this.httpPut(apiPath, payload);
  }

  async cancelOrderStatus(referenceId: string, cancellationReason: string, cancellationReasonId?: string) {
    const payload = {
      cancellationReason,
      cancellationReasonId,
    };
    const apiPath = `${this.customerBaseApiUri}/common/orders/${referenceId}/cancel`;
    return this.httpPatch(apiPath, payload);
  }

  async customerFirstOrder(payload: { listOfOrdersToCheck: IFirstTimeOrderPayload[]; }): Promise<{ data: IListOfOrdersToCheck[]; }> {
    const apiPath = `${this.customerBaseApiUri}/common/orders/customers-first-order`;
    return this.httpPost(apiPath, payload);
  }

  async simplifiedOrder(simplifiedOrderId: string, referenceId: string) {
    const apiPath = `${this.customerBaseApiUri}/common/today-orders/simplified/${simplifiedOrderId}/reference/${referenceId}`;
    return this.httpGet(apiPath);
  }

  async updateCallResponse(referenceId: string, payload: IDelayNotificationPayload) {
    const apiPath = `${this.delayNotificationServicePath}/reference/${referenceId}`;
    return this.httpPost(apiPath, payload);
  }
}
