export enum AppTabAbilityKey {
  CONTROL_TOWER_APPLICATION = 'controlTowerApplication',

  // LIVE ORDERS
  CONTROL_TOWER_LIVE_ORDERS = 'controlTowerLiveOrders',
  CONTROL_TOWER_SUBSCRIPTION_ORDERS = 'controlTowerSubscriptionOrders',
  CONTROL_TOWER_LIVE_ORDERS_ASSIGN_RIDER = 'controlTowerLiveOrdersAssignRider',
  CONTROL_TOWER_LIVE_ORDERS_ADD_NOTES = 'controlTowerLiveOrdersAddNotes',
  CONTROL_TOWER_LIVE_ORDERS_ORDER_DISPUTES = 'cookAdminOrderDisputes',
  CONTROL_TOWER_LIVE_ORDERS_VIEW_ORDER_DISPUTES = 'cookAdminViewOrderDisputes',
  CONTROL_TOWER_LIVE_ORDERS_ADD_ORDER_DISPUTES = 'cookAdminAddOrderDisputes',
  CONTROL_TOWER_LIVE_ORDERS_VIEW_ORDER_DETAILS = 'cookAdminViewOrderDetails',
  CONTROL_TOWER_LIVE_ORDERS_ASSIGN_TO_TPL = 'controlTowerLiveOrdersAssignToTpl',
  CONTROL_TOWER_LIVE_ORDERS_EDIT_ORDER_STATUS = 'controlTowerLiveOrdersEditOrderStatus',

  // 360s
  CONTROL_TOWER_COOK_360 = 'controlTowerCook360',
  CONTROL_TOWER_CUSTOMER_360 = 'controlTowerCustomer360',
  CONTROL_TOWER_DE_360 = 'controlTowerDE360',

  // TRACE MAP
  CONTROL_TOWER_TRACE_MAP = 'controlTowerTraceMap'
}
