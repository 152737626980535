import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { RestApiService } from '../api.service';
import { HttpClientService } from '../http-client.service';

export interface IAbility {
  subjectValue: string;
  actions: Array<string>;
}

export interface IAttributes {
  attributeName: string;
  attributeValue: string | Array<string>;
}

export interface IAdminUser {
  userId?: string;
  managerId: string;
  isActive: boolean;
  userName: string;
  userMailId: string;
  abilities: IAbility[];
  attributes: IAttributes[];
}

@Injectable({
  providedIn: 'root',
})
export class UserApiService extends RestApiService {
  protected override baseApiUrl = environment.config['ADMIN_SERVICE_URI'];

  protected baseApiUrlV2 = environment.config['ADMIN_SERVICE_URI'];

  private adminUserServicePath = `${this.baseApiUrlV2}/admin-users`;

  constructor(http: HttpClientService) {
    super(http);
    this.authenticatedRoute = true;
  }

  async getAdminUserById(adminUserId: string): Promise<{ data: IAdminUser }> {
    const apiPath = `${this.adminUserServicePath}/fetch-user/${adminUserId}`;
    return this.httpGet(apiPath);
  }

}
