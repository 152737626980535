export const environment = {
  envName: 'local',
  production: false,
  config: {
    COOK_SERVICE_URI: 'http://localhost:3000/api/cook-service/v1',
    KITCHEN_SERVICE_URI: 'http://localhost:3001/api/menu-service/v1',
    AUTH_SERVICE_URI: 'http://localhost:3002/api/user-service/v1',
    LOGISTIC_SERVICE_URI: 'http://localhost:3006/api/delivery-service/v1',
    CONFIG_SERVICE_URI: 'http://localhost:3003/api/config-service/v1',
    REVIEW_SERVICE: 'http://localhost:3005/api/review-service/v1',
    ADMIN_SERVICE_URI: 'http://localhost:3007/api/admin-operations-service/v1',
    GOOGLE_MAPS_API_KEY: 'AIzaSyCUlqQY-y4HE9uQ6QYNlz-A7ovI7drPgJE',
    ADMIN_PANEL_URI: 'https://admin.dev.cookr.in/',
    REPORT_DASHBOARD_URI: 'https://reports.dev.cookr.in/',
    REPORT_SERVICE_URI: 'http://localhost:8000/api/report-analytics-service/v1',
    SELLER_PIPELINE_URI: 'https://sellerpipeline.dev.cookr.in/',
    azureAd: {
      CLIENT_ID: 'c798b78d-825c-4f56-83aa-930652c8ab0e',
      AUTHORITY: 'https://login.microsoftonline.com/40a4ede4-f920-4fc1-968a-d08f490515be',
      REDIRECT_URI: '/',
      POST_LOGOUT_REDIRECT_URI: '/',
      API_SCOPE: 'api://2bf21aca-2837-404c-899b-02e1a490f8dd/api.scopes',
      USER_PROFILE_GRAPH_ENDPOINT: 'https://graph.microsoft.com/v1.0/me',
    },
  },
};
