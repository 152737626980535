import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MsalGuardConfiguration, MsalInterceptorConfiguration, MsalService } from '@azure/msal-angular';
import {
  IPublicClientApplication, PublicClientApplication, BrowserCacheLocation,
  InteractionType,
} from '@azure/msal-browser';
import { firstValueFrom } from 'rxjs';
import { isEmpty } from 'lodash';
import { Role } from '../enums/role.enum';
import { environment } from '../environments/environment';

export interface IAzureUserProfile {
  displayName: string,
  givenName: string,
  id: string,
  jobTitle: string,
  mail: string,
  mobilePhone: string,
  surename: string,
  officeLocation: string,
  preferredLanguage: string,
  userPrincipalName: string,
  role: Role,
  abilities?: any,
  attributes?: any,
  businessPhones: string,
  city: string,
  isActive?: boolean,
}
const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1; // Remove this line to use Angular Universal
// Create a Configuration object with your Azure AD configuration
export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: environment.config.azureAd['CLIENT_ID'],
      authority: environment.config.azureAd['AUTHORITY'],
      redirectUri: environment.config.azureAd['REDIRECT_URI'],
      postLogoutRedirectUri: environment.config.azureAd['POST_LOGOUT_REDIRECT_URI'],
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
      storeAuthStateInCookie: isIE,
    },
  });
}
export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: ['User.Read'],
    },
  };
}
export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();
  protectedResourceMap.set(environment.config.azureAd.USER_PROFILE_GRAPH_ENDPOINT, ['User.Read']);
  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap,
  };
}
@Injectable({
  providedIn: 'root',
})
export class AzureAdConfigService {
  public isAuthenticated = false;

  public azureUserProfile!: Partial<IAzureUserProfile>;

  public myReporteeProfiles!: any;

  constructor(
    private msalService: MsalService,
    private http: HttpClient,
  ) { }

  public loadAppConfigurations(): Promise<boolean> {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      try {
        const res = await this.msalService.instance.handleRedirectPromise();
        if (res) {
          this.isAuthenticated = true;
          this.msalService.instance.setActiveAccount(res.account);
          await this.getAzureUserProfile();
          resolve(true);
        } else {
          this.login();
        }
      } catch (error) {
        reject();
      }
    });
  }

  setAuthenticationStatus() {
    let activeAccount = this.msalService.instance.getActiveAccount();
    if (!activeAccount && this.msalService.instance.getAllAccounts().length > 0) {
      // eslint-disable-next-line prefer-destructuring
      activeAccount = this.msalService.instance.getAllAccounts()[0];
      this.msalService.instance.setActiveAccount(activeAccount);
      this.isAuthenticated = !!activeAccount;
    }
  }

  async isAuthenticatedCheck() {
    return this.getAzureUserProfile().then((user: any) => {
      if (!isEmpty(user)) {
        return true;
      }
      return false;
    }).catch(() => false);
  }

  login() {
    this.msalService.instance.loginRedirect();
  }

  logout() {
    this.isAuthenticated = false;
    this.msalService.logoutRedirect();
  }

  async getAzureUserProfile(): Promise<IAzureUserProfile> {
    this.azureUserProfile = await firstValueFrom(
      this.http.get(environment.config.azureAd.USER_PROFILE_GRAPH_ENDPOINT),
    );
    return this.azureUserProfile as IAzureUserProfile;
  }
}
